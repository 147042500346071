import {backendApi} from "../../backend-api-sr"

export const applConstant = (() =>{
    const fetchApplConstant = ( async (subPath, body) => {
        var url = '/api/v3/sms/master/appl-constant'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, body, false, false, false)
    })

    return {fetchApplConstant};
})()